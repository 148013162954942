.success-animation {
  margin: 0 auto;
}

.checkmark {
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #4bb71b;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4bb71b;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  margin: 0 auto;
  max-height: 45px;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}

.daily-notes-modal {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}
.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.modal-header-container {
  padding: 16px;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  justify-content: space-between;
  background: #f8f9fa;

  h2 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
  }

  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
}

.modal-body-container {
  padding: 16px;
  overflow-y: auto;
  flex: 1;
}

.modal-footer-container {
  padding: 16px;
  border-top: 1px solid #dee2e6;
  display: flex;
  justify-content: center;
  background: #f8f9fa;

  .close-footer-button {
    background: #6c757d;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: #5a6268;
    }
  }
}

.table-responsive {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    vertical-align: top;
    align-content: center;
  }

  th {
    background: #f1f1f1;
    font-weight: 600;
  }
}

.notes-textarea {
  width: 100%;
  height: 60px;
  resize: vertical;
  padding: 8px;
  font-size: 1.3rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: inherit;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.submit-button {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #0056b3;
  }

  &:disabled {
    background: #aaa;
    cursor: not-allowed;
  }
}

@media (max-width: 768px) {
  table thead {
    display: none;
  }

  table, 
  table tbody, 
  table tr, 
  table td {
    display: block;
    width: 100%;
  }

  table tr {
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px;
  }

  table td {
    border: none;
    box-sizing: border-box;
    font-size: 0.9rem;
    display: block;
    padding: 4px 0;

    &:before {
      content: attr(data-label);
      display: block;
      font-weight: bold;
      margin-bottom: 4px;
      color: #333;
    }
  }

  .action-td {
    display: block;
    text-align: center;
  }

  .notes-textarea {
    font-size: 1rem;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
}
